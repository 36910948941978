const Boxes = {
    box1: function () {
        let brd = JXG.JSXGraph.initBoard('box1',{axis:false, boundingbox: [-1, 4, 10, -2], showCopyright: false, pan: {enabled: false}, zoom: {enabled: false}, showNavigation: false});
        brd.suspendUpdate();

        var col = 'blue';
        let width=0.5;
        let aux=(brd.create('point',[2, 2], {name: '', size: 0, face: 'square', strokeColor:'black', fillColor:'yellow', shadow: true,}));

        //First polygon
        let p1=(brd.create('point',[1, 1], {name: 'Drag me!', size: 2.5, face: 'square', strokeColor:'black', fillColor:'yellow', shadow: true}));
        let p2=(brd.create('point',[2, 1], {name: '', size: 0, strokeColor:col, fillColor:col, fixed: true}));
        let p3=(brd.create('point',[1, width], {name: '', size: 0, strokeColor:col, fillColor:col}));
        let p4=(brd.create('point',[2, width], {name: '', size: 0, strokeColor:col, fillColor:col, fixed: true}));
        brd.create('polygon', [p1,p3,p4,p2], {fillColor: '#800000', strokeColor: '#800000',withLines:false});
        //var ele=brd.create('slider',[[2,0],[2,5],[0,2,5]],{size:1});
        //Second polygon
        let v1=(brd.create('point',[2, 1-width*0.6], {name: '', size: 0, fixed: true}));
        let v2=(brd.create('point',[2, 2], {name: 'Drag me!', size: 2.5, face: 'square', strokeColor:'black', fillColor:'yellow', shadow: true,}));
        let v3=(brd.create('point',[2+width*0.6, 2], {name: '', size: 0, strokeColor:col, fillColor:col}));
        let v4=(brd.create('point',[2+width*0.6, 1-width], {name: '', size: 0, fixed: true}));
        brd.create('polygon', [p4,v2,v3,v4], {fillColor: '#800000', strokeColor: '#800000', fixed: true, withLines:false});
        //Third polygon
        let g1=(brd.create('point',[2+width*0.6, 2-width], {name: '', size: 0}));
        let g2=(brd.create('point',[2+width*0.6, 2], {name: '', size: 0}));
        let g3=(brd.create('point',[3, v2.Y()+0.25], {name: '', size: 0, face: 'square', strokeColor:'black', fillColor:'yellow', shadow: true,}));
        let g4=(brd.create('point',[3, v2.Y()-width-0.25], {name: '', size: 0}));
        brd.create('polygon', [g1,g2,g3,g4], {fillColor: '#800000', strokeColor: '#800000', strokeWidth:0,withLines:false});

        // Velocity 1 Slider
        var vel1 = brd.create('slider',[[5,3.5],[6, 3.5], [1.0, 1.0, 10]],{baseline:{strokeWidth:7, strokeColor:'grey'}, highline:{strokeWidth:7, strokeColor:'#800000'}, face:'square', fillColor:'#800000',strokeColor:'black', withTicks:false, withLabel: false});

        // Preasure 1 Slider
        var pre1 = brd.create('slider',[[5,2.5],[6, 2.5], [0, 50, 100]],{baseline:{strokeWidth:7, strokeColor:'grey'}, highline:{strokeWidth:7, strokeColor:'#800000'}, face:'square', fillColor:'#800000',strokeColor:'black', withTicks:false, withLabel: false});
        // Entry arrow
        brd.create('arrow', [[0, 0.7], [0.5,0.7]], {strokeColor: '#800000', strokeWidth:5});
        brd.create('text', [0.2, 1, 'v_1'], {fontSize:20});

        // End arrow
        let endArrow = brd.create('arrow', [[3.5,1.7], [4, 1.7]], {strokeColor: '#800000', strokeWidth:5});
        brd.create('text', [3.7, 2, 'v_2'], {fontSize:20});

        // Continuity equation A1 * v1 = A2 * v2
        // So, v2 = (A1 * v1)/A2
        const rho = 1;

        var A1 = function () { return  Math.abs(p1.Y()-p3.Y()) };
        var A2 = function () { return Math.abs(g3.Y()-g4.Y()) }
        let vv2 = function () { return A1() * vel1.Value() / A2() };
        let A2overA1=function () { return A2()/A1()};
        let P2 = function () {
            return pre1.Value() + .5 * rho * Math.pow(vel1.Value(),2) + rho*9.8*0 - 5 * rho * Math.pow(vv2(),2) - rho*9.8*v2.Y()
        };

        brd.on('move', function() {
            if (p1.Y() < 1){
                p1.moveTo([p1.X(), 1]);
            }
            p1.moveTo([1, p1.Y()]);
            p3.moveTo([1, 1-width/2-(p1.Y() - 1 + width/2)]);

            v2.moveTo([2, v2.Y()]);
            v3.moveTo([v3.X(), v2.Y()]);

            g1.moveTo([g1.X(), v2.Y()-width]);
            g2.moveTo([g2.X(), v2.Y()]);
            g3.moveTo([3,v2.Y()+0.25]);
            let a = 2-width/2-(g3.Y() - 2 + width/2);
            g4.moveTo([3, v2.Y()-width-0.25]);

        });
        // Input values
        brd.create('text', [5, 3, 'v_1 = '], {fontSize:20, fixed: true});
        brd.create('text', [5.5, 3, function () { return vel1.Value().toFixed(2) + ' m/s'}], {fontSize:20, fixed: true});
        brd.create('text', [5, 2, 'P_1 = '], {fontSize:20, fixed: true});
        brd.create('text', [5.5, 2, function () { return pre1.Value().toFixed(2) + ' MPa'}], {fontSize:20, fixed: true});
        brd.create('text', [5, 1.5, '\\(\\rho = 1 \\ kg/m^3\\), constant'], {useMathJax: true, parse: false, fontSize: 20, fixed: true});
        // Outout values
        brd.create('text', [7, 3.5, 'v_2 ='], {fontSize:20, fixed: true});
        brd.create('text', [7.5, 3.5, function () { return vv2().toFixed(2) + ' m/s'}], {fontSize:20, fixed: true});
        brd.create('text', [7, 3, 'P_2 ='], {fontSize:20, fixed: true});
        brd.create('text', [7.5, 3, function () { return P2().toFixed(2) + ' MPa'}], {fontSize:20, fixed: true});
        brd.create('text', [7, 2.5, 'A_2/A_1 ='], {fontSize:20, fixed: true});
        brd.create('text', [7.8, 2.5, function () { return A2overA1().toFixed(2)+ ' '}], {fontSize:20, fixed: true});
        brd.unsuspendUpdate();
    },
}
export default Boxes;
